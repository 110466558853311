import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import './scss/style.scss';
import './index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getObjectData } from './services/Storage';

const loading = (
   <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
   </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Forgot = React.lazy(() => import('./views/pages/Forgot/forgot'));
const SetPassword = React.lazy(() =>
   import('./views/pages/Forgot/setPassword'),
);
const LandingPage = React.lazy(() =>
   import('./views/pages/LandingPage/LandingPage'),
);
const ServiceStation = React.lazy(() =>
   import('./views/pages/ServiceStation/serviceStation'),
);
const InspectionBooking = React.lazy(() =>
   import('./views/pages/inspectionBooking'),
);
const Blog = React.lazy(() => import('../src/views/pages/Blog/Blog'));

const BlogContent = React.lazy(() =>
   import('../src/views/pages/Blog/BlogContent'),
);
const MyBooking = React.lazy(() => import('../src/views/pages/myBookings'));
const Privacy = React.lazy(() => import('./views/pages/Privacy/privacy'));
const TermOfUse = React.lazy(() => import('./views/pages/Privacy/termOfUse'));
const Notice = React.lazy(() => import('./views/pages/Privacy/notice'));
const ScheduleDetails = React.lazy(() =>
   import('./views/dashboard/ScheduleDetails'),
);

function App() {
   const [isAuthenticated, setIsAuthenticated] = useState(true);

   useEffect(() => {
      checkAuthState();
   }, []);

   const checkAuthState = async () => {
      let userDetail = await getObjectData('auth');
      let loggedIn = userDetail ? true : false;
      setIsAuthenticated(loggedIn);
   };

   const AuthenticatedRoutes = ({ render, isAuthenticated }) => {
      if (!isAuthenticated) {
         return <Navigate to="/login" />;
      }
      return render;
   };

   return (
      <div>
         <ToastContainer autoClose={1500} />
         <BrowserRouter>
            <Suspense fallback={loading}>
               <Routes>
                  <Route
                     exact
                     path="/login"
                     name="Login Page"
                     element={<Login setIsAuthenticated={setIsAuthenticated} />}
                  />
                  <Route
                     exact
                     path="/register"
                     name="Register Page"
                     element={<Register />}
                  />
                  <Route
                     exact
                     path="/forgot"
                     name="Forgot Page"
                     element={<Forgot />}
                  />
                  <Route
                     exact
                     path="/set-password"
                     name="Passwrod Page"
                     element={<SetPassword />}
                  />
                  <Route
                     exact
                     path="/"
                     name="Landing Page"
                     element={<LandingPage />}
                  />
                  <Route
                     exact
                     path="/blog"
                     name="Landing Page"
                     element={<Blog />}
                  />
                  <Route
                     exact
                     path="/privacy-policy"
                     name="Landing Page"
                     element={<Privacy />}
                  />
                  <Route
                     exact
                     path="/terms-of-use"
                     name="Landing Page"
                     element={<TermOfUse />}
                  />
                  <Route
                     exact
                     path="/booking-terms"
                     name="Landing Page"
                     element={<Notice />}
                  />
                  <Route
                     exact
                     path="/user/search-station"
                     name="SearchStation"
                     element={<ServiceStation />}
                  />
                  <Route
                     exact
                     path="/user/inspection-booking"
                     name="inspectionBooking"
                     element={<InspectionBooking />}
                  />
                  <Route
                     exact
                     path="/user/blog"
                     name="blog"
                     element={<Blog />}
                  />
                  <Route
                     exact
                     path="/user/blog/:slug"
                     name="blogContent"
                     element={<BlogContent />}
                  />
                  <Route
                     exact
                     path="/user/mybooking"
                     name="myBooking"
                     element={<MyBooking />}
                  />
                  <Route
                     path="*"
                     name="Home"
                     element={
                        <AuthenticatedRoutes
                           render={<DefaultLayout />}
                           isAuthenticated={isAuthenticated}
                        />
                     }
                  />
               </Routes>
            </Suspense>
         </BrowserRouter>
      </div>
   );
}

export default App;
